section {
	user-select: none;
	.col-6 {
		&:first-of-type {
			background-color: #ffeee2;
		}
		.row.bg-white {
			background-color: white;
			box-shadow: -1px -1px 10px rgba(34, 36, 38, 0.15);
			padding: 1.2rem;
		}

		.heading {
			h1 {
				font-size: 1.5rem;
				margin-bottom: 0.25rem;
				font-weight: 600;
			}
			p {
				font-style: italic;
			}
		}

		.ms-3 > svg {
			fill: var(--bs-primary);
			font-size: 1.5rem;
			display: inline-block;
		}

		.ms-3.followup {
			background-color: white;
			padding: 0.5rem 1rem;
			border-radius: 1rem;
			input {
				flex: 1;
				background: transparent;
				box-shadow: none;
				outline: none;
				border: none;
			}
		}

		.form-check-label {
			font-weight: 500;
			font-size: 1.1rem;
		}
	}
}
.prescription {
	display: flex;
	position: relative;
	flex-direction: column;
	margin: auto;
	padding: 1rem;
	// outline: 1px solid #d0cece;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	line-height: 1rem;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	.align-left {
		text-align: left !important;
	}

	.line {
		display: flex;
		.text-center.head {
			font-size: 13px;
		}
		p.rx {
			font-size: 17px;
		}
	}

	.head {
		text-align: left;
		font-weight: bold;
		// margin-right: 0.5rem;
		white-space: nowrap;
		.signature {
			width: 110px;
			height: 40px;
			object-fit: fill;
		}
	}
	.data {
		white-space: break-spaces;
	}

	.presc-head {
		display: flex;
		padding: 0 1rem;
		line-height: 1.2rem;
		justify-content: flex-start;
		align-items: flex-end;
		.orgDetails {
			.orgName {
				font-size: 20px;
				margin-bottom: 0.5rem;
				font-weight: bold;
				text-align: left;
			}
			.orgAddr {
				text-align: left;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.data {
					text-align: left;
				}
			}
		}
		.presc-logo {
			img {
				width: 80px;
				height: 80px;
				object-fit: fill;
			}
		}
	}

	.patientDetails {
		display: grid;
		grid-template-columns: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
		grid-gap: 5px;
		margin: 0.8rem 0;
		padding: 0.5rem;
		border: 0.1rem solid black;
		.col {
			.line {
				margin: 0.2rem 0;
			}
		}
	}

	.prescTable {
		border-collapse: collapse;
		width: 98%;
		tbody {
			display: flex;
			flex-direction: column;
		}
		thead {
			display: flex;
			background-color: #bcbcbc;
		}
		tr {
			border: 1px solid black;
			border-left: 0;
			border-right: 0;
			display: flex;
			width: 100%;
		}
		th {
			padding: 0.3rem 0;
			text-align: left;
			flex: 1;
		}
		td {
			text-align: left;
			padding: 0.3rem 0;
			flex: 1;
		}
	}

	.doc-name {
		display: flex;
		align-items: flex-end;
		margin-bottom: 1rem;
	}

	.qr {
		width: 100%;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: flex-end;
		.qr-hint {
			font-size: 8px;
			font-weight: 500;
			text-align: right;
		}
	}

	.pdf-footer {
		width: 100%;
		border-top: 2px solid;
		border-bottom: 2px solid;
		.footer-text {
			font-weight: 600;
		}
	}
}
p {
	margin-bottom: 0 !important;
}

.dropdown-btn {
	display: inline-block !important;
}
