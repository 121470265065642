.container {
	//background: linear-gradient(to right, #e7a270, #dc8c5b, #d28652, #c47c46);
	 background: linear-gradient(100deg, #FF9652 6%, #FFFFFF 120.51%);

	border-radius: 1rem;
width: 100%;
}

.cardLayout {
	// background-color: rgba(249, 203, 173, 0.3);	
	background-color: white;
	border-radius: 0.5rem;
	padding: 1rem;
	color: black;
	
	border: 0.5px solid rgb(100, 100, 100);
	box-shadow: 0px 0px 10px 2px rgba(83, 83, 83, 0.1);

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid black;
		font-weight: 500;
		font-size: 16px;
		padding-bottom: 10px;
		.details {
			display: inline-block; 
			padding-top: 5px;
			padding-bottom: 5px;
			background-color: black;
			color: white;
			border-radius: 20px;
			font-size: 12px;
			cursor: pointer;
			width: 80px;
			text-align: center;
		}
	}
	.progressText {
		font-size: 10px;
		font-weight: 300;
		margin-right: 4px;
		margin-left: 4px;
		width: 60px; 
		text-align: center;
	}
	.description {
		font-weight: 400;
		font-size: 12px;
		margin-top: 10px;
		padding-bottom: 2px;
	}
	.date {
		font-weight: 700;
		font-size: 22px;
	}
	.subHeading {
		font-weight: 700;
		font-size: 14px;
	}
	.smallText {
		font-style: italic;
		font-size: 12px;
		font-weight: 300;
	}
	
}

.details {
	display: inline-block; 
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: black;
	color: white;
	border-radius: 20px;
	font-size: 12px;
	cursor: pointer;
	width: 80px;
	text-align: center;
}

.width-20 {
	width: 130px;
}

.width-50 {
	width: 47%;
}

.width-75 {
	width: 75%;
}

.width-100 {
	width: 100%;
}


.progress {
    background-color: transparent; 
   // border: 1px solid #e7a270; 
    border-radius: 5px; 
}

.progress-bar {
    background-color: #FF9652; 
}
.text-wrap {
	white-space: normal; 
	overflow-wrap: break-word; 
	word-wrap: break-word; 
	display: block; 
  }