//
// Custom
//

@use 'sass:map';
@import 'patient/summary';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
	appearance: textfield;
}

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.document-row {
	margin-top: 1px;
}

.chatbot {
	z-index: 100;
	position: absolute;
	bottom: 50px;
	right: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: end;
	.chatbot-dropdown {
		background-color: #fff;
		padding: 15px;
		border-radius: 10px;
		padding-right: 40px;
		ul {
			list-style-type: none;
			li {
				color: #ff9652 !important;
				line-height: 40px;
			}
		}
		.inline-buttons {
			padding: 10px;
			border: 2.5px solid #d3d3d3;
			color: #ff9652;
			border-radius: 25px;
			margin-top: 10px;
		}
	}
	.chatbot-btn {
		width: 80px;
		height: 80px;
		background-color: #ff9652;
		border-radius: 50%;
		cursor: pointer;
		img {
			width: 40px;
			// height: 40px;
		}
	}
}

.df-jcfe {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.stockist-select {
	width: 250px;
	margin-right: 10px;
}

.df-jcfs {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.df-jcsb {
	display: flex;
	justify-content: space-between;
}

.chatbot-tile {
	width: 360px;
	position: relative;
	background-color: #fff;
	box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
	border-radius: 10px;
	.header-chat {
		background-color: #ff9652;
		padding: 10px;
		border-radius: 10px;
		color: #fff;
		// text-align: center;
		// display: flex;
		// flex-direction: row;
		// position: absolute;
		// top:0px;
		.name {
			margin-top: 5px;
		}
	}
	.bottom-input {
		// position: absolute;
		// bottom: 0px;
		display: flex;
		flex-direction: row;
		box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
		padding: auto;
		input {
			width: 290px;
		}
		.send-btn {
			width: 70px;
			height: 50px;
			background-color: #ff9652;
			border-radius: 0px 10px 10px 0px;
			color: #fff;
			cursor: pointer;
		}
	}
	.fields {
		height: 450px;
		overflow-y: scroll;
		.message {
			padding: 5px;
			border-radius: 10px;
			margin: 10px;
			margin-bottom: 0px;
			&.user {
				background-color: #ff9652;
				color: #fff;
				text-align: right;
			}
			&.bot {
				background-color: #d3d3d3;
				color: #000;
				text-align: left;
			}
			.bot-message {
				background-color: #d3d3d3;
				color: #000;
				text-align: left;
				margin-left: 0px;
				border-radius: 10px;
				padding: 10px;
				width: 85%;
			}
			.user-message {
				background-color: #ff9652;
				color: #fff;
				text-align: right;
				margin-right: 0px;
				border-radius: 10px;
				padding: 10px;
				width: 85%;
			}
		}
	}
}

.image-for-case-sheet {
	max-width: 200px;
}
